import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import { useTranslation } from 'react-i18next';

const PasswordResetConfirm: React.FC = () => {
    const { uidb64, token } = useParams<{ uidb64: string; token: string }>();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleSubmit = async () => {
        try {
            await AuthService.resetPassword(uidb64!, token!, password, confirmPassword);
            setMessage(t('passwordResetConfirm.success'));
            setError(null);
            setTimeout(() => navigate('/login'), 3000);
        } catch (error) {
            setMessage(null);
            setError(t('passwordResetConfirm.error'));
        }
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="w-full max-w-md bg-white rounded p-8 shadow-lg">
                <h2 className="text-2xl mb-4">{t('passwordResetConfirm.header')}</h2>
                <div className="mb-4">
                    <label className="block text-gray-700">{t('passwordResetConfirm.newPassword')}</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">{t('passwordResetConfirm.confirmPassword')}</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <button
                    onClick={handleSubmit}
                    className="w-full bg-blue-500 text-white p-2 rounded"
                >
                    {t('passwordResetConfirm.submit')}
                </button>
                {message && <div className="mt-4 text-green-500">{message}</div>}
                {error && <div className="mt-4 text-red-500">{error}</div>}
            </div>
        </div>
    );
};

export default PasswordResetConfirm;
