import React, { useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import GuideService from "../../../services/GuideService";
import {useTranslation} from "react-i18next";

interface Category {
    id: number;
    title: string;
}

interface CategorySelectProps {
    categories: Category[];
    setCategories: (categories: Category[]) => void;
}

const CategorySelect: React.FC<CategorySelectProps> = ({ categories, setCategories }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const { t, i18n } = useTranslation();

    const loadOptions = async (inputValue: string, callback: (options: Category[]) => void) => {
        const response = await GuideService.getCategories(inputValue);
        callback(response);
        return response;
    };

    const handleInputChange = (newValue: string) => {
        setInputValue(newValue);
        return newValue;
    };

    const handleChange = (selectedOptions: any) => {
        setCategories(selectedOptions || []);
    };

    const handleCreate = (inputValue: string) => {
        const newCategory = { id: -1, title: inputValue };
        setCategories([...categories, newCategory]);
    };

    const getOptionLabel = (option: any) => {
        if (option.__isNew__) {
            return option.label;
        }
        return option.title;
    };

    const getOptionValue = (option: any) => {
        if (option.__isNew__) {
            return option.value;
        }
        return option.id.toString();
    };

    return (
        <div className="text-center">
            <AsyncCreatableSelect
                value={categories}
                isMulti
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                onInputChange={handleInputChange}
                onChange={handleChange}
                onCreateOption={handleCreate}
                placeholder={t('categorySelect.placeholder')}
            />
        </div>
    );
};

export default CategorySelect;
