import React, { useState, useEffect } from 'react';
import UserService from "../../services/UserService";
import {User} from "../../entities/User";
import {useDispatch} from "react-redux";
import {updateProfile} from "../../store/userSlice";
import {AppDispatch} from "../../store";
import {convertFileToBase64} from "../../utils";
import {useTranslation} from "react-i18next";


interface EditProfileFormProps {
    user: User;
    onSave: (updatedUser: User) => void;
    onCancel: () => void;
}

const EditProfileForm: React.FC<EditProfileFormProps> = ({ user, onSave, onCancel }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [username, setUsername] = useState(user.username);
    const [description, setDescription] = useState(user.description);
    const [avatar, setAvatar] = useState<File | null>(null);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [previewAvatar, setPreviewAvatar] = useState<string>(user.avatar);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (avatar) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewAvatar(reader.result as string);
            };
            reader.readAsDataURL(avatar);
        } else {
            setPreviewAvatar(user.avatar);
        }
    }, [avatar, user.avatar]);

    const handleSave = async () => {
        const updatedFields: Partial<User> = {};

        if (username !== user.username) {
            updatedFields.username = username;
        }
        if (description !== user.description) {
            updatedFields.description = description;
        }
        if (avatar) {
            updatedFields.avatar = await convertFileToBase64(avatar);
        }
        if (newPassword && oldPassword) {
            await UserService.changePassword(oldPassword, newPassword);
        }

        if (Object.keys(updatedFields).length > 0) {
            await dispatch(updateProfile({ id: user.id, ...updatedFields }));
        }
    };

    const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setAvatar(e.target.files[0]);
        }
    };

    return (
        <div className="p-6 bg-white border rounded shadow-lg">
            <h2 className="text-2xl font-bold mb-4">{t('editProfileForm.title')}</h2>
            <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 mb-4 md:mb-0 md:mr-4">
                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2">{t('editProfileForm.username')}</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2">{t('editProfileForm.avatar')}</label>
                        <div className="flex items-center">
                            <img src={previewAvatar} alt="Avatar" className="w-16 h-16 rounded-full mr-4" />
                            <input type="file" onChange={handleAvatarChange} className="w-full p-2 border rounded" />
                        </div>
                    </div>
                    <div className="mb-4 p-4 border rounded">
                        <h3 className="text-lg font-bold mb-2">{t('editProfileForm.change_password')}</h3>
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2">{t('editProfileForm.old_password')}</label>
                            <input
                                type="password"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2">{t('editProfileForm.new_password')}</label>
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                        </div>
                    </div>
                </div>
                <div className="md:w-1/2">
                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2">{t('editProfileForm.description')}</label>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="w-full p-2 border rounded h-64"
                        ></textarea>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button onClick={onCancel} className="mr-4 p-2 bg-gray-300 rounded">{t('editProfileForm.cancel')}</button>
                <button onClick={handleSave} className="p-2 bg-blue-500 text-white rounded">{t('editProfileForm.save')}</button>
            </div>
        </div>
    );
};

export default EditProfileForm;
