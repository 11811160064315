import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import WalletService from '../../services/WalletService';
import { Transaction } from '../../entities/Transaction';
import {Wallet} from "../../entities/Wallet";
import {RootState} from "../../store";
import {useSelector} from "react-redux";
import WalletInfo from "../../components/fintech/WalletInfo";
import TransactionsList from "../../components/fintech/TransactionsList";

const FintechPage: React.FC = () => {
    const { user , isAuthenticated} = useSelector((state: RootState) => state.user);
    const [transactions, setTransactions] = useState<any[]>([]);

    const handleUpdateTransactions = async () => {
        const transactionsData = await WalletService.getTransactions();
        setTransactions(transactionsData);
    };

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mx-auto p-4">
            <WalletInfo onUpdateTransactions={handleUpdateTransactions} />
            <TransactionsList transactions={transactions} onRefresh={handleUpdateTransactions} />
        </div>
    );
};

export default FintechPage;
