import React from 'react';
import {useTranslation} from "react-i18next";

interface CostStepProps {
    cost: number;
    setCost: (cost: number) => void;
}

const CostStep: React.FC<CostStepProps> = ({ cost, setCost }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="text-center">
            <h3 className="text-xl font-semibold mb-2">{t('costStep.title')}</h3>
            <input
                type="number"
                value={cost}
                onChange={(e) => setCost(parseFloat(e.target.value))}
                className="w-full p-2 border rounded mb-4"
                placeholder={t('costStep.placeholder')}
            />
        </div>
    );
};

export default CostStep;
