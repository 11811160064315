import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useTranslation } from 'react-i18next';

const EmailConfirmationBanner: React.FC = () => {
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.user.user);

    if (!user || user.isEmailConfirmed) {
        return null;
    }

    const handleResendEmail = () => {
        // Логика для повторной отправки письма
    };

    return (
        <div className="bg-yellow-300 text-yellow-900 p-4 text-center">
            {t('emailConfirmation.message')}
            <button onClick={handleResendEmail} className="underline ml-2">
                {t('emailConfirmation.resend')}
            </button>
        </div>
    );
};

export default EmailConfirmationBanner;
