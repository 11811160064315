import React from 'react';
import ImageUploader from '../../common/ImageUploader';
import {useTranslation} from "react-i18next";

interface CoverStepProps {
    cover: File | null;
    setCover: (cover: File | null) => void;
    coverUrl: string | null;
}

const CoverStep: React.FC<CoverStepProps> = ({ cover, setCover, coverUrl }) => {
    const { t, i18n } = useTranslation();
    return <ImageUploader label={t('coverStep.image_label')} imageFile={cover} setImageFile={setCover} imageUrl={coverUrl} />;
};

export default CoverStep;
