import React from 'react';
import {useTranslation} from "react-i18next";

interface MessageStepProps {
    successMessage: string | null;
    errorMessage: string | null;
    onCancel: () => void;
}

const MessageStep: React.FC<MessageStepProps> = ({ successMessage, errorMessage, onCancel }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className="text-center">
            {successMessage && (
                <div className="text-green-500">
                    <h2 className="text-2xl font-bold mb-4">{t('messageStep.success')}</h2>
                    <p>{successMessage}</p>
                </div>
            )}
            {errorMessage && (
                <div className="text-red-500">
                    <h2 className="text-2xl font-bold mb-4">{t('messageStep.error')}</h2>
                    <p>{errorMessage}</p>
                </div>
            )}
            <button onClick={onCancel} className="mt-4 p-2 bg-blue-500 text-white rounded">{t('messageStep.close')}</button>
        </div>
    );
};

export default MessageStep;
