import React, {useEffect, useState} from 'react';
import SearchBar from '../../components/common/SearchBar';
import Categories from '../../components/common/Categories';
import GuidesGrid from "../../components/guides/GuidesGrid";
import {useLocation} from "react-router-dom";

const Home: React.FC = () => {
    const location = useLocation();
    const initialCategory = (() => {
        const params = new URLSearchParams(location.search);
        const categoryParam = params.get('category');
        return categoryParam ? Number(categoryParam) : undefined;
    })();


    const [category, setCategory] = useState<number | undefined>(initialCategory);
    const [search, setSearch] = useState<string | undefined>();

    console.log('Rendering Home with category:', category, 'and search:', search);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const categoryParam = params.get('category');
        if (categoryParam) {
            setCategory(Number(categoryParam));
        } else {
            setCategory(undefined);
        }
    }, [location.search]);


    const params = {
        category,
        search
    };

    return (
        <div className="container mx-auto p-4">
            <SearchBar setSearch={setSearch} />
            <Categories setCategory={setCategory} />
            <GuidesGrid params={params} />
        </div>
    );
};

export default Home;
