import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Guide } from '../entities/Guide';

interface GuidesState {
    guides: Guide[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: GuidesState = {
    guides: [],
    status: 'idle',
    error: null,
};

const guidesSlice = createSlice({
    name: 'guides',
    initialState,
    reducers: {
        setGuides: (state, action: PayloadAction<Guide[]>) => {
            state.guides = action.payload;
        },
    },
});

export const { setGuides } = guidesSlice.actions;

export default guidesSlice.reducer;
