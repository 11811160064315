import React from 'react';
import { useDropzone } from 'react-dropzone';
import {useTranslation} from "react-i18next";

interface FileStepProps {
    fileUrl: File | null;
    setFileUrl: (file: File | null) => void;
}

const FileStep: React.FC<FileStepProps> = ({ fileUrl, setFileUrl }) => {
    const { t, i18n } = useTranslation();
    const onDrop = (acceptedFiles: File[]) => {
        setFileUrl(acceptedFiles[0]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div className="flex flex-col items-center justify-center text-center">
            <p className="mb-4">{t('fileStep.description')}</p>
            <div
                {...getRootProps()}
                className={`w-full max-w-lg p-6 border-2 border-dashed rounded-lg cursor-pointer transition-colors ${
                    isDragActive ? 'border-blue-500' : 'border-gray-300'
                }` }
                style={{ minHeight: '200px' }}
            >
                <input {...getInputProps()} />
                {fileUrl ? (
                    <p>{fileUrl.name}</p>
                ) : (
                    <p>{t('fileStep.placeholder')}</p>
                )}
            </div>
        </div>
    );
};

export default FileStep;
