import React from 'react';
import { User } from '../../entities/User';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface AuthorInfoProps {
    author: User;
}

const AuthorInfo: React.FC<AuthorInfoProps> = ({ author }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className="mt-4 flex items-center">
            <img src={author.avatar} alt={author.username} className="w-16 h-16 rounded-full" />
            <div className="ml-4">
                <Link
                    className="text-xl font-bold"
                    to={`/profile/${author.id}`}
                >
                    {author.username}
                </Link>

                <p>{t('authorInfo.guides')}: {author.guidesCount}</p>
                <p>{t('authorInfo.downloads')}: {author.downloads}</p>
                <p>{t('authorInfo.average_rating')}: {author.averageRating}</p>
            </div>
        </div>
    );
};

export default AuthorInfo;
