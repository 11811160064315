import React from 'react';
import {useTranslation} from "react-i18next";

interface GuideDetailActionProps {
    isPurchased: boolean;
    isAuthored: boolean;
    handlePurchase: () => void;
    handleDownload: () => void;
    handleEdit: () => void;
}

const GuideDetailAction: React.FC<GuideDetailActionProps> = ({ isPurchased, isAuthored, handlePurchase, handleDownload, handleEdit }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className="mt-4">
            {isAuthored ? (
                <>
                    <button
                        onClick={handleDownload}
                        className="w-full p-2 mb-2 rounded bg-primary text-white"
                    >
                        {t('guideDetailAction.download')}
                    </button>
                    <button
                        onClick={handleEdit}
                        className="w-full p-2 rounded bg-blue-600 text-white"
                    >
                        {t('guideDetailAction.edit')}
                    </button>
                </>
            ) : (
                <button
                    onClick={isPurchased ? handleDownload : handlePurchase}
                    className={`w-full p-2 rounded ${isPurchased ? 'bg-primary' : 'bg-blue-500'} text-white`}
                >
                    {isPurchased ?  t('guideDetailAction.download') : t('guideDetailAction.purchase')}
                </button>
            )}
        </div>
    );
};

export default GuideDetailAction;
