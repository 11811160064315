import axios from 'axios';
import AuthService from '../services/AuthService';

const apiWithAuth = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

apiWithAuth.interceptors.request.use((config) => {
    const token = AuthService.getToken();
    if (token) {
        config.headers.Authorization = `Token ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default apiWithAuth;
