import React from 'react';

interface TransactionsListProps {
    transactions: Array<{
        transaction_id: string;
        amount: number;
        timestamp: string;
        status: string;
    }>;
    onRefresh: () => void;
}

const TransactionsList: React.FC<TransactionsListProps> = ({ transactions, onRefresh }) => {
    return (
        <div className="p-4 bg-white rounded shadow-md mt-4">
            <h2 className="text-2xl font-bold mb-4">Транзакции</h2>
            <button onClick={onRefresh} className="mb-4 bg-blue-500 text-white px-4 py-2 rounded">
                Обновить
            </button>
            <ul>
                {transactions.map((txn) => (
                    <li key={txn.transaction_id} className="mb-2">
                        <div>Время: {new Date(txn.timestamp).toLocaleString()}</div>
                        <div>Сумма: {txn.amount} USDT</div>
                        <div>Статус: {txn.status}</div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TransactionsList;
