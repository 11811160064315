import React from 'react';

interface ProgressBarProps {
    currentStep: number;
    totalSteps: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ currentStep, totalSteps }) => {
    const progress = (currentStep / totalSteps) * 100;

    return (
        <div className="w-3/4 mx-auto mt-4">
            <div className="bg-gray-300 h-1">
                <div className="bg-green-600 h-1" style={{ width: `${progress}%` }}></div>
            </div>
        </div>
    );
};

export default ProgressBar;
