import UserService from "./UserService";
import api from "../api/api";


class AuthService {
    private static instance: AuthService;

    private constructor() {}

    public static getInstance(): AuthService {
        if (!AuthService.instance) {
            AuthService.instance = new AuthService();
        }
        return AuthService.instance;
    }

    public async login(email: string, password: string) {
        const response = await api.post('/accounts/login/', { email, password });
        if (response.data.token) {
            this.setToken(response.data.token);
            return response.data;
        }
        return response.data;
    }

    public async register(username: string, email: string,  password: string) {
        const response = await api.post('/accounts/register/', { username, email, password });
        return response.data;
    }

    public logout() {
        localStorage.removeItem('token');
    }

    public setToken(token: string) {
        localStorage.setItem('token', token);
    }

    public getToken() {
        return localStorage.getItem('token');
    }

    public isAuthenticated() {
        return !!this.getToken();
    }

    public async checkUsername(username: string): Promise<boolean> {
        try {
            const response = await api.get(`/accounts/user/check_username/?username=${username}`);
            return response.data.available;
        } catch (error) {
            console.error("Username check error:", error);
            return false;
        }
    }

    public async requestPasswordReset(email: string): Promise<void> {
        try {
            await api.post('/accounts/password-reset/', { email });
        } catch (error) {
            console.error('Password reset request error:', error);
            throw error;
        }
    }

    public async resetPassword(uidb64: string, token: string, password: string, confirmPassword: string): Promise<void> {
        try {
            await api.post(`/accounts/password-reset-confirm/${uidb64}/${token}/`, { password, confirmPassword });
        } catch (error) {
            console.error('Password reset error:', error);
            throw error;
        }
    }
}

export default AuthService.getInstance();
