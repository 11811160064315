import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GuideService from '../../services/GuideService';
import { Guide } from '../../entities/Guide';
import GuideForm from './GuideForm';

const EditGuide: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [guide, setGuide] = useState<Guide | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) return;
        const gid = parseInt(id, 10);
        const fetchGuide = async () => {
            const response = await GuideService.getGuide(gid);
            if (response) {
                setGuide(response);
            }
        };

        fetchGuide();
    }, [id]);

    const handleSave = async (updatedGuide: Guide) => {
        if (!id) return;
        const gid = parseInt(id, 10);
        navigate(`/guides/${gid}`);
    };

    const handleCancel = () => {
        if (!id) return;
        const gid = parseInt(id, 10);
        navigate(`/guides/${gid}`);
    };

    if (!guide) return <div>Loading...</div>;

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="container mx-auto p-4">
                <GuideForm onSave={handleSave} onCancel={handleCancel} guide={guide}/>
            </div>
        </div>
    );
};

export default EditGuide;
