import React, { useState, useEffect } from 'react';
import GuideService from '../../services/GuideService';
import { Guide } from '../../entities/Guide';
import TitleStep from './steps/TitleStep';
import DescriptionStep from './steps/DescriptionStep';
import CoverStep from './steps/CoverStep';
import FileStep from './steps/FileStep';
import MessageStep from './steps/MessageStep';
import DeleteStep from './steps/DeleteStep';
import { FiX } from 'react-icons/fi';
import ProgressBar from "../common/ProgressBar";
import { convertFileToBase64 } from "../../utils";
import CostStep from "./steps/CostStep";
import CategorySelect from "./steps/CategorySelect";
import {useTranslation} from "react-i18next";

interface GuideFormProps {
    guide?: Guide;
    onSave: (guide: Guide) => void;
    onCancel: () => void;
}

const GuideForm: React.FC<GuideFormProps> = ({ guide, onSave, onCancel }) => {
    const [step, setStep] = useState(1);
    const [title, setTitle] = useState(guide ? guide.title : '');
    const [description, setDescription] = useState(guide ? guide.description : '');
    const [cover, setCover] = useState<File | null>(null);
    const [coverUrl, setCoverUrl] = useState<string | null>(guide ? guide.cover : null);
    const [file, setFileUrl] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [cost, setCost] = useState(guide ? guide.cost : 0);
    const [categories, setCategories] = useState(guide ? guide.categories : []);

    const { t, i18n } = useTranslation();


    useEffect(() => {
        if (guide) {
            setTitle(guide.title);
            setDescription(guide.description);
        }
    }, [guide]);

    const handleNext = () => {
        setStep(step + 1);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSave = async () => {
        if (!title || !description || (guide ? false : (!cover || !file))) {
            setError(t('guideForm.fill_all_fields'));
            return;
        }

        setUploading(true);
        setError(null);
        setSuccess(null);

        const formData = new FormData();
        const coverBase64 = cover ? await convertFileToBase64(cover) : null;
        formData.append('title', title);
        formData.append('description', description);
        if (coverBase64) {
            formData.append('cover', coverBase64);
        }
        if (file) {
            formData.append('content', file);
        }

        formData.append('cost', cost.toString());
        formData.append('categories', JSON.stringify(categories));

        try {
            const savedGuide = guide
                ? await GuideService.updateGuide(guide.id, formData)
                : await GuideService.uploadGuide(formData);
            setSuccess(t('guideForm.save_success'));
            setStep(7);  // Move to the message step
            onSave(savedGuide!);
        } catch (error) {
            console.error('Save guide error:', error);
            setError(t('guideForm.save_error'));
            setStep(7);  // Move to the message step
        } finally {
            setUploading(false);
        }
    };

    const handleDeleteConfirm = async () => {
        if (guide) {
            try {
                await GuideService.deleteGuide(guide.id);
                setSuccess(t('guideForm.delete_success'));
                setStep(7);  // Move to the message step
            } catch (error) {
                console.error('Delete guide error:', error);
                setError(t('guideForm.delete_error'));
                setStep(7);  // Move to the message step
            }
        }
    };

    return (
        <div className="relative p-6 bg-white border rounded shadow-lg w-full mx-auto transition-all duration-500" style={{ minHeight: '570px' }}>
            <button className="absolute top-2 right-2 p-2" onClick={onCancel}>
                <FiX className="text-gray-500 hover:text-gray-700" size={24} />
            </button>
            <h2 className="text-2xl font-bold mb-4 text-center font-header">{guide ? t('guideForm.edit') : t('guideForm.upload')}</h2>
            {error && <p className="text-red-500 text-center">{error}</p>}
            {success && <p className="text-green-500 text-center">{success}</p>}
            {step === 1 && <CategorySelect categories={categories} setCategories={setCategories} />}

            {step === 2 && <DescriptionStep description={description} setDescription={setDescription} />}
            {step === 3 && <CoverStep cover={cover} setCover={setCover} coverUrl={coverUrl} />}
            {step === 4 && <FileStep fileUrl={file} setFileUrl={setFileUrl} />}
            {step === 5 && <CostStep cost={cost} setCost={setCost} />}

            {step === 6 && <TitleStep title={title} setTitle={setTitle} />}

            {step === 7 && <MessageStep successMessage={success} errorMessage={error} onCancel={onCancel} />}
            {step === 8 && <DeleteStep onDeleteConfirm={handleDeleteConfirm} onCancel={handleBack} />}

            {step < 7 && (
                <div className="absolute bottom-0 left-0 right-0 p-6 bg-white border-t">
                    <ProgressBar currentStep={step} totalSteps={guide ? 8 : 7} />
                    <div className="flex justify-between items-center mt-4">
                        {step > 1 && <button onClick={handleBack} className="p-2 bg-gray-300 rounded">{t('guideForm.back')}</button>}
                        {step < 6 ? (
                            <button onClick={handleNext} className="p-2 bg-blue-500 text-white rounded ml-auto">{t('guideForm.next')}</button>
                        ) : (
                            <button onClick={handleSave} className="p-2 bg-blue-500 text-white rounded ml-auto" disabled={uploading}>
                                {uploading ? 'Uploading...' : t('guideForm.save')}
                            </button>
                        )}
                        {guide && (
                            <button onClick={() => setStep(6)} className="p-2 bg-red-500 text-white rounded ml-2">{t('guideForm.delete')}</button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default GuideForm;
