import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
    const { t, i18n } = useTranslation();

    return (
        <footer className="bg-gray-100 py-4 mt-8 border-t border-gray-200">
            <div className="container mx-auto flex justify-between items-center">
                <div className="text-gray-700">© 2024 Guidepark</div>
                <div className="flex space-x-4">
                    <Link to="/about" className="text-gray-700 hover:text-gray-900">
                        {t('footer.about')}
                    </Link>
                    <Link to="/terms" className="text-gray-700 hover:text-gray-900">
                        {t('footer.terms')}
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
