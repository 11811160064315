import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from "../../services/AuthService";
import { useDispatch } from "react-redux";
import { setAuthenticated, setError, setStatus, setUser } from "../../store/userSlice";
import { useTranslation } from "react-i18next";
import Loader from "../../components/common/Loader";

const Register: React.FC = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleRegister = async () => {
        if (password !== confirmPassword) {
            setErrorMessage(t('register.error.passwordsDoNotMatch'));
            return;
        }

        dispatch(setStatus('loading'));
        setErrorMessage(null);
        setIsLoading(true);
        try {
            const response = await AuthService.register(username, email, password);
            if (response.token) {
                dispatch(setUser(response.user));
                dispatch(setAuthenticated(true));
                dispatch(setStatus('succeeded'));
                navigate('/');
            } else {
                setErrorMessage(t('register.error.registrationFailed'));
                dispatch(setError('Registration failed'));
                dispatch(setStatus('failed'));
            }
        } catch (error: any) {
            setErrorMessage(t('register.error.registrationError'));
            dispatch(setError('Registration error: ' + error.message));
            dispatch(setStatus('failed'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="w-full max-w-md bg-white rounded p-8 shadow-lg">
                <h2 className="text-2xl mb-4">{t('register.header')}</h2>
                <div className="mb-4">
                    <label className="block text-gray-700">{t('register.username')}</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">{t('register.email')}</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">{t('register.password')}</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">{t('register.confirmPassword')}</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                {errorMessage && (
                    <div className="mb-4 text-red-500">
                        {errorMessage}
                    </div>
                )}
                <button
                    onClick={handleRegister}
                    className="w-full bg-blue-500 text-white p-2 rounded flex justify-center items-center"
                    disabled={isLoading}
                >
                    {isLoading ? <Loader /> : t('register.button')}
                </button>
            </div>
        </div>
    );
};

export default Register;
