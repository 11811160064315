import React from 'react';
import {useTranslation} from "react-i18next";

interface DescriptionStepProps {
    description: string;
    setDescription: (description: string) => void;
}

const DescriptionStep: React.FC<DescriptionStepProps> = ({ description, setDescription }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className="text-center">
            <p className="mb-4">{t('descriptionStep.placeholder')}</p>
            <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full p-2 border rounded h-32 mb-4"
            ></textarea>
        </div>
    );
};

export default DescriptionStep;
