import React from 'react';
import { User } from '../../entities/User';
import {useTranslation} from "react-i18next";

interface UserInfoProps {
    user: User;
}

const UserInfo: React.FC<UserInfoProps> = ({ user }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="flex flex-col md:flex-row mb-8">
            <div className="md:w-1/3 p-4">
                <img src={user.avatar} alt={user.username} className="w-32 h-32 rounded-full mx-auto" />
                <h2 className="text-2xl font-bold text-center mt-4">{user.username}</h2>
                <p className="text-center">{t('userInfo.average_rating')}: {user.averageRating}</p>
            </div>
            <div className="md:w-2/3 p-4">
                <h3 className="text-xl font-bold">{t('userInfo.description')}</h3>
                <p>{user.description}</p>
            </div>
        </div>
    );
};

export default UserInfo;
