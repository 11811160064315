import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import guidesReducer from './guidesSlice';
import categoriesReducer from './categoriesSlice';
import walletReducer from './walletSlice';

const store = configureStore({
    reducer: {
        user: userReducer,
        guides: guidesReducer,
        categories: categoriesReducer,
        wallet: walletReducer
    },
    devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
