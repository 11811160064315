import React from 'react';
import {useTranslation} from "react-i18next";

interface TitleStepProps {
    title: string;
    setTitle: (title: string) => void;
}

const TitleStep: React.FC<TitleStepProps> = ({ title, setTitle }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className="text-center">
            <p className="mb-4">{t('titleStep.description')}</p>
            <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full p-2 border rounded mb-4"
            />
        </div>
    );
};

export default TitleStep;
