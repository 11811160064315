import api from '../api/api';
import apiWithAuth from '../api/apiWithAuth';
import { Guide } from '../entities/Guide';
import {Category} from "../entities/Category";

interface GuideResponse {
    results: Guide[];
    count: number;
    next: string | null;
    previous: string | null;
}

class GuideService {
    private static instance: GuideService;

    private constructor() {}

    public static getInstance(): GuideService {
        if (!GuideService.instance) {
            GuideService.instance = new GuideService();
        }
        return GuideService.instance;
    }

    public async getGuides(params: {
        page?: number;
        category?: number;
        search?: string;
        is_purchased?: boolean;
        is_own?: boolean;
        is_favorites?: boolean;
    }): Promise<GuideResponse | null> {
        try {
            const response = await apiWithAuth.get<GuideResponse>('/guides/guide', {params});
            return response.data;
        } catch (error) {
            console.error("Failed to fetch guides:", error);
            return null;
        }
    }

    public async getGuide(id: number): Promise<Guide | null> {
        try {
            const response = await api.get<Guide>(`/guides/guide/${id}/`);
            return response.data;
        } catch (error) {
            console.error("Failed to fetch guide:", error);
            return null;
        }
    }

    public async updateGuide(id: number, formData: FormData): Promise<Guide | null> {
        try {
            const response = await api.patch<Guide>(`/guides/guide/${id}/`, formData);
            return response.data;
        } catch (error) {
            console.error("Failed to update guide:", error);
            return null;
        }
    }

    public async deleteGuide(id: number): Promise<void> {
        try {
            await apiWithAuth.delete(`/guides/guide/${id}/`);
        } catch (error) {
            console.error("Failed to delete guide:", error);
        }
    }

    public async addFavorite(guideId: number): Promise<void> {
        try {
            await apiWithAuth.post(`/guides/guide/${guideId}/favorite/`);
        } catch (error) {
            console.error("Change favorite error:", error);
        }
    }

    public async purchaseGuide(guideId: number): Promise<void> {
        try {
            await apiWithAuth.post(`/fintech/purchase/`, { guide_id: guideId });
        } catch (error) {
            console.error("Purchase guide error:", error);
        }
    }

    public async downloadGuide(guideId: number): Promise<void> {
        try {
            const response = await apiWithAuth.get(`/guides/guide/${guideId}/download/`, {
                responseType: 'blob',
            });
            // Откроем полученный URL в новом окне или вкладке для скачивания
            const url = window.URL.createObjectURL(new Blob([response.data]));
            window.open(url, '_blank');
        } catch (error) {
            console.error("Download guide error:", error);
        }
    }

    public async uploadGuide(formData: FormData): Promise<Guide> {
        try {
            const response = await apiWithAuth.post('/guides/guide/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Upload guide error:', error);
            throw error;
        }
    }

    async getCategories(search? : string): Promise<Category[]> {
        const response = await api.get('/guides/category/', {params: {search}});
        return response.data;
    }

}

export default GuideService.getInstance();
