// TagsInfo.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Tag {
    id: number;
    title: string;
}

interface TagsInfoProps {
    tags: Tag[];
}

const TagsInfo: React.FC<TagsInfoProps> = ({ tags }) => {
    const navigate = useNavigate();

    const handleTagClick = (tagId: number) => {
        navigate(`/?category=${tagId}`);
    };

    return (
        <div className="mt-4 flex flex-wrap">
            {tags.map((tag) => (
                <span
                    key={tag.id}
                    onClick={() => handleTagClick(tag.id)}
                    className="mr-2 mb-2 px-2 py-1 bg-gray-200 text-sm font-semibold text-gray-700 rounded-full cursor-pointer hover:bg-gray-300"
                >
                    #{tag.title}
                </span>
            ))}
        </div>
    );
};

export default TagsInfo;
