import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setUser } from '../../store/userSlice';
import {Guide} from "../../entities/Guide";
import UserService from "../../services/UserService";
import {User} from "../../entities/User";
import EditProfileForm from "../../components/profile/EditProfileForm";
import UserInfo from "../../components/profile/UserInfo";
import GuidesGrid from "../../components/guides/GuidesGrid";
import {FiEdit} from 'react-icons/fi';
import {MdOutlineAddBox} from "react-icons/md";
import {useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Profile: React.FC = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.user);
    const [activeTab, setActiveTab] = useState<'favorites' | 'purchased' | 'authored'>('authored');
    const [isEditing, setIsEditing] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [guidesParams, setGuidesParams] = useState({});
    const { id: profileId } = useParams<{ id: string }>();
    const [profileUser, setProfileUser] = useState<User | null>(null);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetchUser = async () => {
            if (!profileId) return;
            const response = await UserService.getUser(parseInt(profileId, 10));
            if (response) {
                setProfileUser(response);
                setGuidesParams({ user: response.id })
            }
        };

        fetchUser();
    }, []);


    useEffect(() => {
        const fetchGuides = async () => {
            setGuidesParams({ is_own: true });
            if (profileUser) {
                switch (activeTab) {
                    case 'favorites':
                        setGuidesParams({...guidesParams, is_favorites: true, is_own: false, is_purchased: false});
                        break;
                    case 'purchased':
                        setGuidesParams({...guidesParams, is_purchased: true, is_own: false, is_favorites: false});
                        break;
                    case 'authored':
                        setGuidesParams({...guidesParams, is_own: true, is_favorites: false, is_purchased: false});
                        break;
                }
            }
        };

        fetchGuides();
    }, [profileUser, activeTab]);

    const handleSave = (updatedUser: User) => {
        dispatch(setUser(updatedUser));
        setIsEditing(false);
    };

    const isCurrentUserProfile = user && user.id === parseInt(profileId!, 10);

    if (!user) return <div>Loading...</div>;

    return (
        <div className="container mx-auto p-4">
            {isEditing ? (
                <EditProfileForm user={user} onSave={handleSave} onCancel={() => setIsEditing(false)} />
            ) : (
                <>
                    {profileUser && <UserInfo user={profileUser!} />}
                    {isCurrentUserProfile && (
                        <div className="flex justify-center mt-2 space-x-4">
                            <button onClick={() => setIsEditing(true)} className="flex items-center text-blue-500 hover:text-blue-700">
                                <FiEdit className="mr-2" />
                                <span>{t('profile.edit')}</span>
                            </button>
                            <button onClick={() => setIsUploading(true)} className="flex items-center text-green-500 hover:text-green-700">
                                <MdOutlineAddBox className="mr-2" />
                                <span>{t('profile.upload_guide')}</span>
                            </button>
                        </div>
                    )}
                </>
            )}
            <hr className="my-4" />
            <div className="flex justify-center mb-4">
                {isCurrentUserProfile ? (
                    <>
                        <button
                            className={`p-2 ${activeTab === 'favorites' ? 'border-b-2 border-blue-500' : ''}`}
                            onClick={() => setActiveTab('favorites')}
                        >
                            {t('profile.favorites')}
                        </button>
                        <button
                            className={`p-2 ${activeTab === 'purchased' ? 'border-b-2 border-blue-500' : ''}`}
                            onClick={() => setActiveTab('purchased')}
                        >
                            {t('profile.purchased')}
                        </button>
                        <button
                            className={`p-2 ${activeTab === 'authored' ? 'border-b-2 border-blue-500' : ''}`}
                            onClick={() => setActiveTab('authored')}
                        >
                            {t('profile.own')}
                        </button>
                    </>
                ) : null}

            </div>
            <GuidesGrid params={guidesParams} />
        </div>
    );
};

export default Profile;
