import apiWithAuth from "../api/apiWithAuth";
import api from "../api/api";
import {Guide, Purchase} from "../entities/Guide";
import {User} from "../entities/User";


class UserService {
    private static instance: UserService;

    private constructor() {}

    public static getInstance(): UserService {
        if (!UserService.instance) {
            UserService.instance = new UserService();
        }
        return UserService.instance;
    }

    public async getMe(): Promise<User> {
        try {
            const response = await apiWithAuth.get('/accounts/user/get_me/');
            return response.data;
        } catch (error) {
            console.error('Get user error:', error);
            throw error;
        }
    }

    public async getUser(userId: number): Promise<User> {
        try {
            const response = await api.get(`/accounts/user/${userId}`);
            return response.data;
        } catch (error) {
            console.error('Get user error:', error);
            throw error;
        }
    }

    public async purchaseGuide(guideId: number): Promise<Purchase> {
        try {
            const response = await apiWithAuth.post(`/fintech/purchases/`, { guide: guideId });
            return response.data;
        } catch (error) {
            console.error('Purchase guide error:', error);
            throw error;
        }
    }

    public async addFavorite(guideId: number): Promise<void> {
        try {
            await apiWithAuth.post(`/guides/guide/${guideId}/favorite/`);
        } catch (error) {
            console.error('Add to favorite error:', error);
            throw error;
        }
    }

    public async removeFavorite(guideId: number): Promise<void> {
        try {
            await apiWithAuth.post(`/guides/guide/${guideId}/favorite/`);
        } catch (error) {
            console.error('Remove from favorite error:', error);
            throw error;
        }
    }

    public async getFavorites(): Promise<Guide[]> {
        try {
            const response = await apiWithAuth.get('/accounts/user/favorites/');
            return response.data;
        } catch (error) {
            console.error('Get favorites error:', error);
            throw error;
        }
    }

    public async getPurchasedGuides(): Promise<Guide[]> {
        try {
            const response = await apiWithAuth.get('/accounts/user/purchases/');
            return response.data;
        } catch (error) {
            console.error('Get purchases error:', error);
            throw error;
        }
    }

    public async getAuthoredGuides(): Promise<Guide[]> {
        try {
            const response = await apiWithAuth.get('/accounts/user/authored/');
            return response.data;
        } catch (error) {
            console.error('Get purchases error:', error);
            throw error;
        }
    }

    public async updateProfile(user: Partial<User>): Promise<User> {
        try {
            const response = await apiWithAuth.patch(`/accounts/user/${user.id}/`, user);
            return response.data;
        } catch (error) {
            console.error('Update profile error:', error);
            throw error;
        }
    }

    public async uploadAvatar(formData: FormData): Promise<{ avatar: string }> {
        try {
            const response = await apiWithAuth.post('/user/upload-avatar/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Upload avatar error:', error);
            throw error;
        }
    }

    public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
        try {
            await apiWithAuth.post('/accounts/user/change_password/', { new_password: newPassword, old_password: oldPassword });
        } catch (error) {
            console.error('Change password error:', error);
            throw error;
        }
    }

    public async rateGuide(guideId: number, rate: number): Promise<void> {
        try {
            await apiWithAuth.post(`/guides/guide/${guideId}/rate/`, { rate });
        } catch (error) {
            console.error('Rate guide error:', error);
            throw error;
        }
    }
}

export default UserService.getInstance();
