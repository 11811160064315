import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import AuthService from '../services/AuthService';
import {User} from '../entities/User';
import UserService from "../services/UserService";
import {RootState} from "./index";

interface UserState {
    isAuthenticated: boolean;
    user: User | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: UserState = {
    isAuthenticated: !!localStorage.getItem('token'),
    user: null,
    status: 'idle',
    error: null,
};


export const fetchUser = createAsyncThunk(
    'user/fetchUser',
    async () => {
        return await UserService.getMe();
    }
);

export const updateProfile = createAsyncThunk(
    'user/updateProfile',
    async (user: Partial<User>, { rejectWithValue }) => {
        try {
            const response = await UserService.updateProfile(user);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const addFavorite = createAsyncThunk(
    'user/addFavorite',
    async (guideId: number, { getState }) => {
        const state = getState() as { user: UserState };
        if (state.user.user) {
            await UserService.addFavorite(guideId);
            return guideId;
        }
        throw new Error('User not authenticated');
    }
);

export const removeFavorite = createAsyncThunk(
    'user/removeFavorite',
    async (guideId: number, { getState }) => {
        const state = getState() as { user: UserState };
        if (state.user.user) {
            await UserService.removeFavorite(guideId);
            return guideId;
        }
        throw new Error('User not authenticated');
    }
);


export const purchaseGuide = createAsyncThunk(
    'user/purchaseGuide',
    async (guideId: number, { getState }) => {
        const state = getState() as { user: UserState };
        if (state.user.user) {
            return await UserService.purchaseGuide(guideId);
        }
        throw new Error('User not authenticated');
    }
);

export const rateGuide = createAsyncThunk(
    'user/rateGuide',
    async ({ id, rate }: { id: number, rate: number }, { getState }) => {
        const state = getState() as RootState;
        const userId = state.user.user!.id;
        const response = await UserService.rateGuide(id, rate);
        return { guideId: id, userId, rate: rate };
    }
);


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        },
        setUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload;
        },
        setStatus: (state, action: PayloadAction<'idle' | 'loading' | 'succeeded' | 'failed'>) => {
            state.status = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = null;
            state.status = 'idle';
            state.error = null;
            AuthService.logout();
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUser.fulfilled, (state, action: PayloadAction<User>) => {
                state.user = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(addFavorite.fulfilled, (state, action: PayloadAction<number>) => {
                if (state.user) {
                    state.user.favorites.push(action.payload);
                }
            })
            .addCase(removeFavorite.fulfilled, (state, action: PayloadAction<number>) => {
                if (state.user) {
                    state.user.favorites = state.user.favorites.filter(id => id !== action.payload);
                }
            })
            .addCase(purchaseGuide.fulfilled, (state, action) => {
                if (state.user) {
                    state.user.purchases.push(action.payload);
                }
            })
            .addCase(rateGuide.fulfilled, (state, action: PayloadAction<{ guideId: number, userId: number, rate: number }>) => {
                const { guideId, rate } = action.payload;
                const purchase = state.user!.purchases.find(purchase => purchase.guide === guideId);
                if (purchase) {
                    purchase.rate = rate;
                }
            })
    },
});

export const {setAuthenticated, setUser, setStatus, setError, logout } = userSlice.actions;

export default userSlice.reducer;
