import {Transaction} from "../entities/Transaction";
import {Wallet} from "../entities/Wallet";
import apiWithAuth from "../api/apiWithAuth";


class WalletService {
    private static instance: WalletService;

    private constructor() {}

    public static getInstance(): WalletService {
        if (!WalletService.instance) {
            WalletService.instance = new WalletService();
        }
        return WalletService.instance;
    }
    public async getWallet(): Promise<Wallet> {
        try {
            const response = await apiWithAuth.get('/fintech/wallet/get_my');
            return response.data;
        } catch (error) {
            console.error('Get wallet error:', error);
            throw error;
        }
    }

    public async getTransactions(): Promise<Transaction[]> {
        try {
            const response = await apiWithAuth.get('/fintech/transactions/get_my');
            return response.data;
        } catch (error) {
            console.error('Get transactions error:', error);
            throw error;
        }
    }

    public async withdraw(address: string, amount: number): Promise<Wallet> {
        try {
            const response = await apiWithAuth.post('/fintech/wallet/withdraw', { address, amount });
            return response.data;
        } catch (error) {
            console.error('Withdraw funds error:', error);
            throw error;
        }
    }

}

export default WalletService.getInstance();

