import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { fetchWallet } from '../../store/walletSlice';
import DepositBlock from './DepositBlock';
import WithdrawBlock from './WithdrawBlock';
import Tabs from "../common/Tabs";

interface WalletInfoProps {
    onUpdateTransactions: () => void;
}

const WalletInfo: React.FC<WalletInfoProps> = ({ onUpdateTransactions }) => {
    const dispatch = useDispatch<AppDispatch>();
    const wallet = useSelector((state: RootState) => state.wallet.wallet);
    const walletStatus = useSelector((state: RootState) => state.wallet.status);

    useEffect(() => {
        if (walletStatus === 'idle') {
            dispatch(fetchWallet());
        }
    }, [walletStatus, dispatch]);

    if (walletStatus === 'loading') {
        return <div>Loading...</div>;
    }

    if (!wallet) {
        return <div>Failed to load wallet</div>;
    }

    const tabs = [
        { name: 'Пополнить', content: <DepositBlock /> },
        { name: 'Вывести', content: <WithdrawBlock /> },
    ];

    return (
        <div className="p-4 bg-white rounded shadow-md">
            <h2 className="text-2xl font-bold mb-4">Ваш кошелек</h2>
            <Tabs tabs={tabs} />
        </div>
    );
};

export default WalletInfo;
