import React from 'react';
import {Guide} from "../../entities/Guide";
import {Link} from "react-router-dom";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import {useTranslation} from "react-i18next";

interface GuideCardProps {
    guide: Guide;
}

const GuideCard: React.FC<GuideCardProps> = ({ guide }) => {
    const { t, i18n } = useTranslation();

    return (
        <Link to={`/guides/${guide.id}`} className="block">
            <div className="relative max-w-sm rounded overflow-hidden shadow-lg">
                <div className="relative w-full" style={{ paddingBottom: '75%' }}>
                    <img className="absolute inset-0 object-cover w-full h-full" src={guide.cover} alt={guide.title} />
                </div>
                <div className="px-4 py-2">
                    <div className="font-bold text-lg mb-1">{guide.title}</div>
                    <p className="text-gray-700 text-sm">
                        {t('guideCard.author')}: {guide.author.username}
                    </p>
                    <p className="text-gray-700 text-sm">
                        {guide.description}
                    </p>
                </div>
                <div className="px-4 py-2 flex items-center justify-between">
                    <span className="text-sm text-gray-700">{new Date(guide.createdAt).toLocaleDateString()}</span>
                    {guide.reviews > 0 && <ReactStars
                        count={5}
                        value={guide.rating}
                        size={24}
                        activeColor="#ffd700"
                        isHalf={true}
                        edit={false}
                    />
                    }
                </div>
            </div>
        </Link>
    );
};

export default GuideCard;
