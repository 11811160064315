import React, { useState } from 'react';

interface TabsProps {
    tabs: { name: string; content: React.ReactNode }[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState<number>(0);

    return (
        <div>
            <div className="flex justify-center mb-4 border-b border-gray-200">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        onClick={() => setActiveTab(index)}
                        className={`py-2 px-4 ${activeTab === index ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600'}`}
                    >
                        {tab.name}
                    </button>
                ))}
            </div>
            <div className="p-4">
                {tabs[activeTab].content}
            </div>
        </div>
    );
};

export default Tabs;
