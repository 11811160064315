import React from 'react';
import { useTranslation } from 'react-i18next';

const TermsPage: React.FC = () => {
    const { t } = useTranslation('terms');

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">{t('title')}</h1>
            <p className="mb-6">{t('intro')}</p>

            {Object.keys(t('sections', { returnObjects: true })).map((sectionKey: string) => (
                <section key={sectionKey} className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">{t(`sections.${sectionKey}.title`)}</h2>
                    {(t(`sections.${sectionKey}.content`, { returnObjects: true }) as Array<{subtitle: string, points: string[]}>).map((content, index) => (
                        <div key={index} className="mb-6">
                            <h3 className="text-xl font-semibold mb-2">{`${index + 1}. ${content.subtitle}`}</h3>
                            <ol className="list-decimal list-inside">
                                {content.points.map((point, idx) => (
                                    <li key={idx} className="mb-2">{point}</li>
                                ))}
                            </ol>
                        </div>
                    ))}
                </section>
            ))}
        </div>
    );
};

export default TermsPage;
