import React, { useState } from 'react';
import { FaStripe, FaBitcoin, FaCopy } from 'react-icons/fa';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";

const DepositBlock: React.FC = () => {
    const [selectedMethod, setSelectedMethod] = useState<string>('stripe');
    const [copied, setCopied] = useState<boolean>(false);

    const wallet = useSelector((state: RootState) => state.wallet.wallet);

    const handleCopy = () => {
        navigator.clipboard.writeText(wallet?.address || '');
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    if (!wallet) {
        return <div>Loading...</div>;
    }

    return (
        <div className="mt-8 w-full">
            <div className="bg-white p-4 rounded shadow-md">
                <div
                    className={`border p-4 rounded mb-2 cursor-pointer flex items-center ${
                        selectedMethod === 'stripe' ? 'bg-blue-50 border-blue-500' : 'border-gray-300'
                    }`}
                    onClick={() => setSelectedMethod('stripe')}
                >
                    <input
                        type="radio"
                        id="deposit-stripe"
                        name="depositMethod"
                        value="stripe"
                        checked={selectedMethod === 'stripe'}
                        onChange={() => setSelectedMethod('stripe')}
                        className="hidden"
                    />
                    <FaStripe className="text-2xl mr-2" />
                    <label htmlFor="deposit-stripe" className="ml-2">
                        <span className="text-lg font-semibold">Stripe</span>
                        <p className="text-sm text-gray-600">Используйте Stripe для пополнения</p>
                    </label>
                </div>
                {selectedMethod === 'stripe' && (
                    <div className="p-4 bg-gray-100 rounded">
                        <p className="mb-2">Stripe пополнение пока не доступно.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DepositBlock;
