import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Guide } from '../../entities/Guide';
import WalletService from "../../services/WalletService";
import {Wallet} from "../../entities/Wallet";

interface PurchaseModalProps {
    guide: Guide;
    onClose: () => void;
    onConfirm: () => void;
    onTopUp: () => void;
}

const PurchaseModal: React.FC<PurchaseModalProps> = ({ guide, onClose, onConfirm, onTopUp }) => {
    const { user } = useSelector((state: RootState) => state.user);
    const [wallet, setWallet] = useState<Wallet | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchWallet = async () => {
            try {
                const walletData = await WalletService.getWallet();
                setWallet(walletData);
            } catch (error) {
                console.error('Failed to fetch wallet data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchWallet();
    }, []);

    if (!user || !wallet) return null;

    const insufficientFunds = wallet.balance < guide.cost;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-96">
                <h2 className="text-2xl font-bold mb-4">Подтверждение покупки</h2>
                <p className="mb-2"><strong>Название:</strong> {guide.title}</p>
                <p className="mb-2"><strong>Автор:</strong> {guide.author.username}</p>
                <p className="mb-2"><strong>Стоимость:</strong> ${guide.cost}</p>
                <p className="mb-4"><strong>Ваш баланс:</strong> ${wallet.balance}</p>
                {insufficientFunds ? (
                    <div className="text-red-500 mb-4">Недостаточно средств для покупки.</div>
                ) : (
                    <div className="text-green-500 mb-4">У вас достаточно средств для покупки.</div>
                )}
                <div className="flex justify-between">
                    <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded">Отмена</button>
                    {insufficientFunds ? (
                        <button onClick={onTopUp} className="bg-blue-500 text-white px-4 py-2 rounded">Пополнить баланс</button>
                    ) : (
                        <button onClick={onConfirm} className="bg-blue-500 text-white px-4 py-2 rounded">Купить</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PurchaseModal;
