import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { FaUserPlus, FaInfoCircle, FaUpload, FaDollarSign } from 'react-icons/fa';
import AboutCover from '../../assets/images/about-cover.jpg';
import FounderPhoto from '../../assets/images/my-photo.jpg';


const About: React.FC = () => {
    const { t } = useTranslation(['common', 'faq']);
    const steps = [
        {
            icon: <FaUserPlus size={40} className="text-primary mx-auto mb-4" />,
            description: t('common:about.step1')
        },
        {
            icon: <FaInfoCircle size={40} className="text-primary mx-auto mb-4" />,
            description: t('common:about.step2')
        },
        {
            icon: <FaUpload size={40} className="text-primary mx-auto mb-4" />,
            description: t('common:about.step3')
        },
        {
            icon: <FaDollarSign size={40} className="text-primary mx-auto mb-4" />,
            description: t('common:about.step4')
        }
    ];

    const faqItems = t('faq:questions', { returnObjects: true });


    const FAQItem = ({ question, answer }: {question: string, answer: string}) => {
        console.debug(faqItems)

        const [isOpen, setIsOpen] = useState(false);

        const toggleOpen = () => {
            setIsOpen(!isOpen);
        };

        return (
            <div className="border-b border-gray-200">
                <button
                    onClick={toggleOpen}
                    className="w-full text-left px-4 py-4 focus:outline-none flex justify-between items-center hover:bg-gray-100"
                >
                    <span className="text-lg font-semibold">{question}</span>
                    <span className="text-xl">{isOpen ? '-' : '+'}</span>
                </button>
                {isOpen && (
                    <div className="px-4 pb-4 text-gray-700">
                        {answer}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="container mx-auto p-8 w-2/3">
            <div className="relative pb-10">
                <img
                    src={AboutCover}
                    alt="About Guidepark"
                    className="w-full h-full object-cover rounded-lg shadow-md"
                    loading="lazy"
                />
            </div>

            <section className="px-4 py-12">
                <div className="text-center mb-12 py-24">
                    <h1 className="text-6xl font-header text-secondary mb-10">{t('common:about.title')}</h1>
                    <p className="mb-6">{t('common:about.description')}</p>
                </div>

                <div className="container mx-auto text-center py-24 bg-gray-100">
                    <h2 className="text-6xl font-header text-secondary mb-10">{t('common:about.howItWorks')}</h2>
                    <div className="flex justify-center space-x-8 p-8">
                        {steps.map((step, index) => (
                            <div key={index} className="w-64">
                                <div className="mb-4">
                                    {step.icon}
                                </div>
                                <p className="text-sm">{step.description}</p>
                            </div>
                        ))}
                    </div>
                </div>


                <div className="container mx-auto py-24">
                    <h2 className="text-6xl font-header text-center mb-8">{t('faq:title')}</h2>
                    <div className="max-w-2xl mx-auto bg-white shadow-md rounded-lg">
                        {(faqItems as Array<{question: string, answer: string}>).map((item, index) => (
                            <FAQItem key={index} question={item.question} answer={item.answer} />
                        ))}
                    </div>
                </div>


                <div className="container mx-auto py-12 bg-gray-100 py-24">
                    <h2 className="text-6xl font-header text-center mb-8">{t('common:about.contact.title')}</h2>
                    <div className="max-w-2xl mx-auto bg-white shadow-md rounded-lg p-6 flex flex-col items-center">
                        <div className="mb-4 text-center">
                            <h4 className="text-xl font-bold">{t('common:about.contact.email')}</h4>
                            <p>{t('common:about.contact.email_value')}</p>
                            <h4 className="text-xl font-bold">{t('common:about.contact.fraud')}</h4>
                            <p>{t('common:about.contact.fraud_value')}</p>
                        </div>
                    </div>
                </div>



            </section>

        </div>
    );
};

export default About;
