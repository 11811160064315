import React, { useState } from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    XIcon,
    WhatsappIcon,
} from 'react-share';
import {ShareIcon} from "@heroicons/react/16/solid";
import {useTranslation} from "react-i18next";

interface ShareButtonProps {
    url: string;
    title: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ url, title }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation();

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="relative">
            <button
                onClick={toggleOpen}
                className="flex items-center space-x-1 text-gray-700 hover:text-gray-900"
            >
                <ShareIcon className="h-5 w-5" />
                <span>{t('shareButton.share')}</span>
            </button>
            {isOpen && (
                <div className="absolute top-full left-0 mt-2 p-4 bg-white border rounded shadow-lg">
                    <div className="flex space-x-4">
                        <FacebookShareButton url={url} title={title}>
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <TwitterShareButton url={url} title={title}>
                            <XIcon size={32} round />
                        </TwitterShareButton>
                        <WhatsappShareButton url={url} title={title}>
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                    </div>

                    <div className="mt-2">
                        <input
                            type="text"
                            value={url}
                            readOnly
                            className="w-full p-2 border rounded"
                            onClick={(e) => (e.target as HTMLInputElement).select()}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShareButton;
