import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {AppDispatch, RootState} from "../../store";
import {fetchCategories} from "../../store/categoriesSlice";


interface CategoriesProps {
    setCategory: (category: number | undefined) => void;
}

const Categories: React.FC<CategoriesProps> = ({ setCategory }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { categories, status, error } = useSelector((state: RootState) => state.categories);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchCategories());
        }
    }, [status, dispatch]);

    if (status === 'loading') {
        return <div>Loading categories...</div>;
    }

    if (status === 'failed') {
        return <div>Error loading categories: {error}</div>;
    }

    return (
        <div className="mt-4 flex flex-wrap">
            <button onClick={() => setCategory(undefined)} className="mr-2 mb-2 px-2 py-1 bg-gray-200 text-sm font-semibold text-gray-700 rounded-full cursor-pointer hover:bg-gray-300">
                All
            </button>
            {categories.map((cat) => (
                <span
                    key={cat.id}
                    onClick={() => setCategory(cat.id)}
                    className="mr-2 mb-2 px-2 py-1 bg-gray-200 text-sm font-semibold text-gray-700 rounded-full cursor-pointer hover:bg-gray-300"
                >
                    #{cat.title}
                </span>
            ))}
        </div>
    );
};

export default Categories;
