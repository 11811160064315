import React from 'react';
import GuideForm from './GuideForm';
import { Guide } from '../../entities/Guide';
import {useNavigate} from "react-router-dom";

const UploadGuide: React.FC = () => {
    const navigate = useNavigate();

    const handleSave = (newGuide: Guide) => {
        navigate(`/guides/${newGuide.id}`);
    };

    const handleCancel = () => {
        // navigate back
        navigate('/');
    };

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="container mx-auto p-4">
                <GuideForm onSave={handleSave} onCancel={handleCancel} />
            </div>
        </div>
    );
};

export default UploadGuide;
