import React from 'react';
import {useTranslation} from "react-i18next";

interface PaginationProps {
    page: number;
    totalPages: number;
    setPage: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ page, totalPages, setPage }) => {
    const { t, i18n } = useTranslation();
    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNext = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    return (
        <div className="flex justify-center mt-4">
            <button onClick={handlePrevious} disabled={page === 1} className="px-4 py-2 mx-1 bg-gray-300 rounded">
                {t('pagination.previous')}
            </button>
            <span className="px-4 py-2 mx-1">{page} of {totalPages}</span>
            <button onClick={handleNext} disabled={page === totalPages} className="px-4 py-2 mx-1 bg-gray-300 rounded">
                {t('pagination.next')}
            </button>
        </div>
    );
};

export default Pagination;
