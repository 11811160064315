import React, { useEffect, useState } from 'react';

interface ImageUploaderProps {
    label: string;
    imageFile: File | null;
    setImageFile: (file: File | null) => void;
    imageUrl?: string | null;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ label, imageFile, setImageFile, imageUrl }) => {
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    useEffect(() => {
        if (imageFile) {
            const fileUrl = URL.createObjectURL(imageFile);
            setPreviewUrl(fileUrl);
            return () => URL.revokeObjectURL(fileUrl); // Clean up URL object
        }
        setPreviewUrl(imageUrl || null);
    }, [imageFile, imageUrl]);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setImageFile(e.target.files[0]);
        }
    };

    return (
        <div className="text-center">
            <h3 className="text-xl font-semibold mb-2">{label}</h3>
            {previewUrl && (
                <div className="mb-4">
                    <img src={previewUrl} alt="Preview" className="mx-auto max-h-64 rounded" />
                </div>
            )}
            <div className="mb-4">
                <label
                    className="cursor-pointer bg-blue-500 text-white p-2 rounded"
                    htmlFor="file-upload"
                >
                    Загрузить изображение
                </label>
                <input
                    id="file-upload"
                    type="file"
                    onChange={handleImageChange}
                    className="hidden"
                />
            </div>
        </div>
    );
};

export default ImageUploader;
