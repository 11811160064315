import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {Wallet} from "../entities/Wallet";
import WalletService from "../services/WalletService";

interface WalletState {
    wallet: Wallet | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: WalletState = {
    wallet: null,
    status: 'idle',
    error: null,
};

export const fetchWallet = createAsyncThunk('wallet/fetchWallet', async () => {
    const response = await WalletService.getWallet();
    return response;
});


export const withdrawFunds = createAsyncThunk('wallet/withdrawFunds', async ({ address, amount }: { address: string, amount: number }) => {
    const response = await WalletService.withdraw(address, amount);
    return response;
});


const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWallet.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWallet.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.wallet = action.payload;
            })
            .addCase(fetchWallet.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(withdrawFunds.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(withdrawFunds.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.wallet = action.payload;
            })
            .addCase(withdrawFunds.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to withdraw';
            });
    },
});

export default walletSlice.reducer;
