import React from 'react';
import {useTranslation} from "react-i18next";

interface DeleteStepProps {
    onDeleteConfirm: () => void;
    onCancel: () => void;
}

const DeleteStep: React.FC<DeleteStepProps> = ({ onDeleteConfirm, onCancel }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">{t('deleteStep.are_you_sure')}</h2>
            <div className="flex justify-center space-x-4">
                <button onClick={onDeleteConfirm} className="p-2 bg-red-500 text-white rounded">{t('deleteStep.delete')}</button>
                <button onClick={onCancel} className="p-2 bg-gray-300 rounded">{t('deleteStep.cancel')}</button>
            </div>
        </div>
    );
};

export default DeleteStep;
