import React, { useState, useEffect } from 'react';
import GuideCard from './GuideCard';
import {Guide} from "../../entities/Guide";
import GuideService from "../../services/GuideService";
import Pagination from "../layout/Pagination";

interface GuideGridProps {
    params: {
        user?: number;
        category?: number;
        search?: string;
        is_purchased?: boolean;
        is_own?: boolean;
        is_favorites?: boolean;
    };
}

const GuidesGrid: React.FC<GuideGridProps> = ({ params }) => {
    const [guides, setGuides] = useState<Guide[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    console.log('Rendering GuidesGrid with params:', params, 'and page:', page);


    useEffect(() => {
        const fetchGuides = async () => {
            const response = await GuideService.getGuides({ ...params, page });
            if (response) {
                setGuides(response.results);
                setTotalPages(Math.ceil(response.count / 12)); // Assuming 10 guides per page
            }
        };

        fetchGuides();
    }, [page, params]);

    return (
        <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-4">
                {guides.map((guide) => (
                    <GuideCard key={guide.id} guide={guide} />
                ))}
            </div>
            <Pagination page={page} totalPages={totalPages} setPage={setPage} />
        </div>
    );
};

export default GuidesGrid;
