import React, { useState } from 'react';
import {HeartIcon} from "@heroicons/react/16/solid";
import {useTranslation} from "react-i18next";


const SaveButton: React.FC<{ isFavorite: boolean; onClick: () => void; }> = ({ isFavorite, onClick }) => {
    const { t, i18n } = useTranslation();
    return (
        <button
            onClick={onClick}
            className="flex items-center space-x-1 text-gray-700 hover:text-gray-900"
        >
            {isFavorite ? (
                <HeartIcon className="h-5 w-5 text-red-500" />
            ) : (
                <HeartIcon className="h-5 w-5" />
            )}
        </button>
    );
};

export default SaveButton;
