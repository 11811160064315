import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home/Home";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import GuideDetails from "./pages/GuideDetails/GuideDetails";
import NavBar from "./components/layout/NavBar";
import {useDispatch} from "react-redux";
import AuthService from "./services/AuthService";
import {setAuthenticated, setStatus, setUser} from "./store/userSlice";
import UserService from "./services/UserService";
import Profile from "./pages/Profile/Profile";
import Footer from "./components/layout/Footer";
import UploadGuide from "./components/guides/UploadGuide";
import EditGuide from "./components/guides/EditGuide";
import FintechPage from "./pages/Fintech/FintechPage";
import About from "./pages/About/About";
import TermsPage from "./pages/TermsPage/TermsPage";
import EmailConfirmationBanner from "./components/common/EmailConfirmationBanner";
import PasswordReset from "./components/auth/PasswordReset";
import PasswordResetConfirm from "./components/auth/PasswordResetConfirm";

const App: React.FC = () => {
    const dispatch = useDispatch();

    console.log('Rendering App');

    React.useEffect(() => {
        const initializeUser = async () => {
            const token = AuthService.getToken();
            if (token) {
                dispatch(setAuthenticated(true));
                dispatch(setStatus('loading'));
                try {
                    const user = await UserService.getMe();
                    dispatch(setUser(user));
                    dispatch(setStatus('succeeded'));
                } catch (error) {
                    console.error('Failed to load user details:', error);
                    dispatch(setStatus('failed'));
                }
            }
        };

        initializeUser();
    }, [dispatch]);

    return (
        <div className="flex flex-col min-h-screen">
          <Router>
            <EmailConfirmationBanner />
            <NavBar />
              <div className="flex-grow">
                <Routes>
                  <Route path="/" element={<Home />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/guides/:id" element={<GuideDetails />} />
                    <Route path="/profile/:id" element={<Profile />} />
                    <Route path="/upload" element={<UploadGuide />} />
                    <Route path="/edit-guide/:id" element={<EditGuide />} />
                    <Route path="/fintech" element={<FintechPage />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/terms" element={<TermsPage />} />
                    <Route path="/password-reset" element={<PasswordReset />} />
                    <Route path="/password-reset-confirm/:uidb64/:token" element={<PasswordResetConfirm />} />
                </Routes>
              </div>
            <Footer />
          </Router>
        </div>
    );
};

export default App;