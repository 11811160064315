import React, { useState } from 'react';
import { FaStripe, FaBitcoin } from 'react-icons/fa';
import CryptoWithdrawForm from "./elements/CryptoWithdrawForm";


const WithdrawBlock: React.FC = () => {
    const [selectedMethod, setSelectedMethod] = useState<string>('stripe');

    return (
        <div className="mt-8 w-full">
            <h3 className="text-xl font-semibold mb-4">Вывести</h3>
            <div className="bg-white p-4 rounded shadow-md">
                <div
                    className={`border p-4 rounded mb-2 cursor-pointer flex items-center ${
                        selectedMethod === 'stripe' ? 'bg-blue-50 border-blue-500' : 'border-gray-300'
                    }`}
                    onClick={() => setSelectedMethod('stripe')}
                >
                    <input
                        type="radio"
                        id="withdraw-stripe"
                        name="withdrawMethod"
                        value="stripe"
                        checked={selectedMethod === 'stripe'}
                        onChange={() => setSelectedMethod('stripe')}
                        className="hidden"
                    />
                    <FaStripe className="text-2xl mr-2" />
                    <label htmlFor="withdraw-stripe" className="ml-2">
                        <span className="text-lg font-semibold">Stripe</span>
                    </label>
                </div>
                {selectedMethod === 'stripe' && (
                    <div className="p-4 bg-gray-100 rounded">
                        <p className="mb-2">Stripe вывод пока не доступен.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WithdrawBlock;
