import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import GuideService from '../../services/GuideService';
import { Guide } from '../../entities/Guide';
import AuthorInfo from './AuthorInfo';
import ShareButton from "../../components/common/ShareButton";
import SaveButton from "../../components/common/SaveButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { addFavorite, purchaseGuide, removeFavorite, rateGuide } from "../../store/userSlice";
import GuideDetailAction from "../../components/guides/GuideDetailAction";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import TagsInfo from "./TagsInfo";
import PurchaseModal from "../../components/fintech/PurchaseModal";
import {useTranslation} from "react-i18next";

const GuideDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [guide, setGuide] = useState<Guide | null>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { isAuthenticated, user } = useSelector((state: RootState) => state.user);
    const [isFavorite, setIsFavorite] = useState(false);
    const [isPurchased, setIsPurchased] = useState(false);
    const [isAuthored, setIsAuthored] = useState(false);
    const [rate, setRate] = useState<number | undefined>(undefined);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (!id) return;
        const gid = parseInt(id, 10);
        const fetchGuide = async () => {
            const response = await GuideService.getGuide(gid);
            if (response) {
                setGuide(response);
            }
        };

        fetchGuide();
        if (!user) return;

        console.debug(user.purchases)
        for (let i = 0; i < user.purchases.length; i++) {
            if (user.purchases[i].guide === gid) {
                setRate(user.purchases[i].rate);
                setIsPurchased(true);
            }
        }

        setIsFavorite(user.favorites.includes(gid));
        setIsAuthored(user.authored.includes(gid));

    }, [id, user]);

    const handleFavorite = async () => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        if (isFavorite) {
            await dispatch(removeFavorite(guide!.id));
        } else {
            await dispatch(addFavorite(guide!.id));
        }
        setIsFavorite(!isFavorite);
    };

    const handlePurchase = async () => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        setShowPurchaseModal(true);
    };

    const confirmPurchase = async () => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        if (!isPurchased) {
            await dispatch(purchaseGuide(guide!.id));
            setIsPurchased(true);
        }
        setShowPurchaseModal(false);
    };

    const handleDownload = async () => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        await GuideService.downloadGuide(guide!.id);
    };

    const handleEdit = () => {
        navigate(`/edit-guide/${guide!.id}`);
    };

    const handleRatingChange = async (newRating: number) => {
        if (isPurchased) {
            await dispatch(rateGuide({ id: guide!.id, rate: newRating }));
            setRate(newRating);
        }
    };

    const handleTopUp = () => {
        navigate('/fintech');
    };

    if (!guide) return <div>Loading...</div>;

    return (
        <div className="container mx-auto p-4">
            <div className="flex flex-col">
                <div className="md:w-1/2 mx-auto">
                    <div className="flex justify-between items-center">
                        <h2 className="text-3xl font-bold">{guide.title}</h2>
                        <div className="flex space-x-4">
                            <ShareButton url={window.location.href} title={guide.title} />
                            <SaveButton isFavorite={isFavorite} onClick={handleFavorite} />
                        </div>
                    </div>
                    <img src={guide.cover} alt={guide.title} className="w-full mt-4 rounded" />
                    <TagsInfo tags={guide.categories} />
                    <div className="flex flex-col md:flex-row mt-4">
                        <div className="md:w-2/3">
                            <AuthorInfo author={guide.author} />
                            <div className="mt-4">
                                <h3 className="text-2xl font-bold">{t('guideDetail.description')}</h3>
                                <p className="mt-2">{guide.description}</p>
                            </div>
                            {isPurchased && (
                                <div className="mt-4">
                                    <h3 className="text-2xl font-bold">{t('guideDetail.addReview')}</h3>
                                    <ReactStars
                                        count={5}
                                        value={rate ?? 0}
                                        onChange={handleRatingChange}
                                        size={24}
                                        color2={'#ffd700'}
                                        edit={isPurchased}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="md:w-1/3 md:ml-4">
                            <div className="bg-white p-4 rounded shadow-md">
                                <p className="mb-2"><strong>{t('guideDetail.rate')}</strong> {guide.rating}</p>
                                <p className="mb-2"><strong>{t('guideDetail.downloads')}</strong> {guide.downloads}</p>
                                <p className="mb-2"><strong>{t('guideDetail.reviews')}</strong> {guide.reviews}</p>
                                <p className="text-xl font-bold mt-4">${guide.cost}</p>
                                <GuideDetailAction
                                    isPurchased={isPurchased}
                                    isAuthored={isAuthored}
                                    handlePurchase={handlePurchase}
                                    handleDownload={handleDownload}
                                    handleEdit={handleEdit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showPurchaseModal && (
                <PurchaseModal
                    guide={guide}
                    onClose={() => setShowPurchaseModal(false)}
                    onConfirm={confirmPurchase}
                    onTopUp={handleTopUp}
                />
            )}
        </div>
    );
};

export default GuideDetails;
