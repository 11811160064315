import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { logout } from '../../store/userSlice';
import AuthService from '../../services/AuthService';
import { useTranslation } from "react-i18next";

const NavBar: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, user } = useSelector((state: RootState) => state.user);
    const menuRef = useRef<HTMLDivElement>(null);
    const langMenuRef = useRef<HTMLDivElement>(null);
    const { t, i18n } = useTranslation();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleLangMenu = () => {
        setIsLangMenuOpen(!isLangMenuOpen);
    };

    const handleLogout = () => {
        dispatch(logout());
        AuthService.logout();
        navigate('/');
    };

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang);
        setIsLangMenuOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setIsMenuOpen(false);
        }
        if (langMenuRef.current && !langMenuRef.current.contains(event.target as Node)) {
            setIsLangMenuOpen(false);
        }
    };

    useEffect(() => {
        if (isMenuOpen || isLangMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen, isLangMenuOpen]);

    return (
        <nav className="flex items-center justify-between p-4 bg-white shadow-md">
            <div className="flex items-center">
                <Link to="/" className="flex items-center">
                    <img src="/logo512cropped.png" className="h-8 mr-2" />
                </Link>
            </div>
            <div className="flex items-center space-x-4">
                {user && (
                    <Link
                        to={`/upload`}
                        className="text-sm font-medium">
                        {t('navbar.uploadGuide')}
                    </Link>
                )}
                <div className="relative">
                    <button onClick={toggleLangMenu} className="text-sm font-medium">🌐</button>
                    {isLangMenuOpen && (
                        <div ref={langMenuRef} className="absolute right-0 mt-2 w-48 bg-white shadow-lg py-2 z-50">
                            <button onClick={() => handleLanguageChange('en')} className="block px-4 py-2 w-full text-left">English</button>
                            <button onClick={() => handleLanguageChange('ru')} className="block px-4 py-2 w-full text-left">Русский</button>
                            {/* Добавьте дополнительные языковые опции здесь */}
                        </div>
                    )}
                </div>
                <div className="relative">
                    <button onClick={toggleMenu} className="text-sm font-medium">☰</button>
                    {isMenuOpen && (
                        <div ref={menuRef} className="absolute right-0 mt-2 w-48 bg-white shadow-lg py-2 z-50">
                            {isAuthenticated ? (
                                <>
                                    <button onClick={handleLogout} className="block px-4 py-2">{t('navbar.logout')}</button>
                                    {user && (
                                        <>
                                            <Link
                                                to={`/profile/${user.id}`}
                                                className="block px-4 py-2"
                                                onClick={() => setIsMenuOpen(false)}
                                            >
                                                {t('navbar.profile')}
                                            </Link>
                                            <Link
                                                to={`/fintech`}
                                                className="block px-4 py-2"
                                                onClick={() => setIsMenuOpen(false)}
                                            >
                                                {t('navbar.wallet')}
                                            </Link>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Link to="/register" className="block px-4 py-2" onClick={() => setIsMenuOpen(false)}>{t('navbar.register')}</Link>
                                    <Link to="/login" className="block px-4 py-2" onClick={() => setIsMenuOpen(false)}>{t('navbar.login')}</Link>
                                </>
                            )}
                            {user && (
                                <Link
                                    to={`/upload`}
                                    className="block px-4 py-2"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    {t('navbar.uploadGuide')}
                                </Link>
                            )}
                            <Link to="/about" className="block px-4 py-2" onClick={() => setIsMenuOpen(false)}>{t('navbar.about')}</Link>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
